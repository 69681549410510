/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_appsync_graphqlEndpoint": "https://h2fbpo5kzvcsjhos24t2ykxqbq.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-uianbx33azdlvid3un344pogyi",
    "aws_cognito_identity_pool_id": "eu-west-2:2f3235b7-5842-4797-a615-59153e282447",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_gEAJIE74o",
    "aws_user_pools_web_client_id": "igjbo7jikon0sg5p14fb3taco",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "boosk-app-storage145944-main",
    "aws_user_files_s3_bucket_region": "eu-west-2"
};


export default awsmobile;
